
.SampleContainer {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;

}

.SampleOptionsContainerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  transition: all 0.5s;
}

.SampleOptionsContainer {
  width: 700px;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #e7e7e7;
  background-color: #e7e7e760;
  position: relative;
  border-radius: 10px;
  color: #fff;
  position: relative;
  transition: all 0.5s;

}

.SampleOutputFileNameInput {
  display: block;
  padding: 10px;
  width: 80%;
  margin-bottom: 10px;
  margin-left: 15px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  color: #000;
  font-size: 14px;
  background-color: #f7f7f7;
  outline: none;
  transition: all 0.5s;


}

.SampleCheckboxRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0px;
  border-radius: 15px;
  color: #666;
  transition: all 0.5s;
}

.SampleCheckboxTextContainer {
  display: block;
  width: fit-content;
  border: 0px solid #666;
  position: relative;
  color: #666;
  transition: all 0.5s;
}

.SampleCheckboxText {
  user-select: none;
}

.SampleColumnsDropdownButton {
  padding: 5px;
  margin: 5px;
  width: fit-content;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  color: #666;
  background-color: #f7f7f7;
  outline: none;
  transition: all 0.5s;
}

.SampleOutputOptionsInput {
  width: 70px;
  margin: 5px;
  padding: 5px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  color: #000;
  background-color: #f7f7f7;
  outline: none;
  transition: all 0.5s;
}

.SampleOptionsSubmitRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  color: #00a76f;
  transition: all 0.5s;

}

.SampleOptionsSubmitButton {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 10px 10px 15px;
  margin: 5px 5px 5px 5px;
  width: 70px;
  border-radius: 25px;
  color: #fff;
  background-color: #00a76f;
  outline: none;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  transition: all 0.5s;
}

.SampleOptionsSubmitButtonDisabled {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  margin: 5px 5px 5px 5px;
  border: 0px solid #666;
  width: fit-content;
  border-radius: 25px;
  color: #666;
  background-color: #e7e7e7;
  outline: none;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: not-allowed;
  transition: all 0.5s;
}

.SampleContainerDescription {
  display: block;
  text-align: center;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  border: 0px solid #666;
  position: relative;
  border-radius: 15px;
  color: #00a76f;
  transition: all 0.5s;

}

.SampleBottomControlRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(40% - 40px);
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
  background-color: #1c252e;
  min-height: 30px;
  border-radius: 40px;
  transition: all 0.5s;
}

.SampleBottomControlRowHidden {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -200px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(40% - 40px);
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
  background-color: #1c252e;
  min-height: 30px;
  border-radius: 40px;
  transition: all 0.5s;
  pointer-events: none;
  transition: all 0.5s;
}

.SampleBottomControlRowText {
  display: block;
  padding: 10px;
}

.SampleFileListScrollContainer {
  display: block;
  width: 100%;
  height: 80%;
  max-height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  background-color: #f7f7f7;
  transition: all 0.5s;
}