



.settingsContainer {
    display: flex;
    flex-direction: column;
    font-family: 'Avenir_Heavy';
    background-color: #fff;
}

.settingsSections {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 900px;
    margin-top: 60px;
    position: relative;

}

.settingsSectionsTitle {
    display: block;
    margin: 0px 50px 0px 0px;
    cursor: pointer;
    color: #666;
    user-select: none;
    transition: all 0.3s;
}

.settingsSectionsTitleSelected {
    display: block;
    margin: 0px 50px 0px 0px;
    cursor: pointer;
    color: #000;
    user-select: none;
    transition: all 0.3s;
}

.settingsSectionsTitleSelectedUnderline {
    position: absolute;
    bottom: -8px;
    height: 2.5px;
    background-color: #000;
    transition: all 0.3s ease; /* Add transition for smooth sliding */
}



.settingsSection {
    margin-top: 30px;
    width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.settingsSectionGeneralLeft {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    padding: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.settingsSectionGeneralAvatarContainer {
    padding: 8px;
    border: 1px dashed #eaeaea;
    border-radius: 50%;
}

.settingsSectionGeneralAvatar {
    width: 100px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.settingsSectionGeneralIntegrations {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e7e7e7;
    padding: 5px;
    border-radius: 40px;
    width: 80%;
    cursor: pointer;
    user-select: none;
    margin: 10px;
}

.settingsSectionGeneralIntegrationsPlatformImg {
    width: 40px;

}

.settingsSectionGeneralIntegrationsPlatformText {
    display: block;
    width: calc(100% - 40px);
    text-align: center;
}

.settingsSectionGeneralRight {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    padding: 20px;
    width: 550px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
} 

.settingsSectionGeneralRightLeft {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    padding: 10px;
}

.settingsSectionGeneralRightRight {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    padding: 10px;

}


.settingsSectionBillingContainer {
    margin-top: 30px;
    width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.settingsBillingSection {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    padding: 0px;
    width: 750px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
}

.settingsBillingNewCardButton {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 110px;
    color: #00A76F;
    cursor: pointer;
    user-select: none;
    border-radius: 10px;
    padding: 5px;
    transition: all 0.3s;
}

.settingsBillingNewCardButton:hover {
    background-color: rgba(0, 167, 111, 0.08);
}

.settingsBillingSectionTitle {
    display: block;
    width: 90%;
    text-align: left;
    padding: 20px;
    font-size: 18px;
}

.settingsBillingPlanOptionsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}

.settingsBillingPlanOptionSelected {
    border: 2px solid #666;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    width: 175px;
    padding: 20px;
    margin: 0px 10px 10px 10px;
    cursor: pointer;
    transition: all 0.3s;
}

.settingsBillingPlanOption {
    border: 2px solid #e7e7e7;
    border-radius: 10px;
    width: 175px;
    padding: 20px;
    margin: 0px 10px 10px 10px;
    cursor: pointer;
    transition: all 0.3s;

}

.settingsBillingPlanOptionLogo {
    max-width: 60px;
}

.settingsBillingPlanOptionTitle {
    display: block;
    font-size: 12px;
}

.setingsBillingPlanOptionPrice {
    display: block;
    font-size: 18px;

}

.settingsBillingInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    width: 90%;
}

.settingsBillingInfoTitles {
    flex: 1;
}

.settingsBillingInfoTitle {
    display: block;
    color: #666;
    padding: 10px;
}

.settingsBillingInfoValues {
    flex: 2;
}

.settingsBillingInfoValue {
    display: block;
    padding: 10px;

}

.settingsBillingSectionFooter {
    border-top: 1px solid #e7e7e7;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.settingsBillingCancelPlanButton {
    display: block;
    color: #000;
    padding: 7px 15px 7px 15px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.3s;

}

.settingsBillingSavePlanButton {
    display: block;
    color: #fff;
    padding: 7px 15px 7px 15px;
    border: 1px solid #000;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
    background-color: #000;
    transition: all 0.3s;
}



.settingsBillingPaymentMethods {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;
}

.settingsBillingPaymentMethod {
    display: column;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    padding: 10px 20px 20px 20px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.3s;
    position: relative;
}

.settingsBillingPaymentMethodMoreIcon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    color: #666;
}

.settingsBillingPaymentMethodCreditCardLogo {
    width: 60px;
    height: 80px;
}

.settingsBillingPaymentMethodNumber {

}


.settingsNotificationsSection {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    padding: 0px;
    width: 750px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;
    padding: 20px;
}

.settingsNotificationsSectionTitle {
    display: block;
    text-align: left;
    font-size: 16px;
}

.settingsNotificationsSectionTitleDescription {
    display: block;
    text-align: left;
    font-size: 12px;
    color: #aaa;
    padding-bottom: 20px;

}

.settingsNotificationsSectionRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.settingsNotificationsSectionDescription {
    display: block;
    color: #666;
}




.securitySection {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    padding: 0px;
    width: 750px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;
    padding: 20px;
}

.settingsSecuritySectionTitle {
    display: block;
    width: 90%;
    text-align: left;
    padding-bottom: 20px;
    font-size: 18px;
}

.settingsSecuritySectionSaveButton {
    display: block;
    color: #fff;
    padding: 7px 15px 7px 15px;
    border: 1px solid #000;
    border-radius: 10px;
    margin-left: auto;
    cursor: pointer;
    background-color: #000;
    transition: all 0.3s;
}





.settingsSectionBusinessContainer {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    border: 1px solid #e7e7e7;
    border-radius: 20px;
    padding: 0px;
    width: 750px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    position: relative;
    padding: 20px;
}

.settingsSectionBusinessContainerTitle {
    display: block;
    width: 90%;
    text-align: left;
    padding-bottom: 20px;
    font-size: 18px;

}

.settingsBusinessEmployees {

}
.settingsBusinessEmployeeRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.settingsBusinessEmployeeRowLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.settingsBusinessEmployeeRowAvatar {
    width: 40px;
    border-radius: 50%;
    margin-right: 20px;
}

.settingsBusinessEmployeeRowName {
    display: block;
}

.settingsBusinessEmployeeRowRight {
    position: relative;
}

.settingsBusinessEmployeeDropdownButtonText {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    
}

.settingsBusinessEmployeeDropdownContainer {
    position: absolute;
    top: 35px;
    left: 0px;
    background-image: url('https://minimals.cc/assets/cyan-blur.png'), url('https://minimals.cc/assets/red-blur.png');
    background-repeat: no-repeat, no-repeat;
    background-size: 50%, 50%;
    background-position: right top, left bottom;
    box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px;
    border-radius: 15px;
    width: fit-content;
    min-width: 100px;
    max-width: 200px;
    overflow: hidden;
    background-color: #fff;
}

.settingsBusinessEmployeeDropdownRow {
    display: block;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    margin: 3px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s;
}

.settingsBusinessEmployeeDropdownRow:hover {
    background-color: #e7e7e7
}


.settingsBusinessEmployeeRowRole {
    display: block;
    text-align: right;
}







.settingsNewPaymentMethodPopupBackground {
    min-width: 100vw;
    min-height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #01030cb4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    transition: all 0.5s;
}

.settingsNewPaymentMethodPopup {
    min-width: 500px;
    background-color: #fff;
    border-radius: 15px;
    transition: all 0.5s;
    padding: 20px;

}

.settingsNewPaymentMethodPopupTitle {
    display: block;
    font-size: 20px;
    padding-bottom: 20px;
}

.settingsNewPaymentMethodPopupExpAndCVVRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.settingsNewPaymentMethodPopupSSLMessage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #aaa;

}

.settingsNewPaymentMethodPopupFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.settingsNewPaymentMethodPopupCancelButton {
    display: block;
    color: #000;
    padding: 7px 15px 7px 15px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.3s;
}

.settingsNewPaymentMethodPopupAddButton {
    display: block;
    color: #fff;
    padding: 7px 15px 7px 15px;
    border: 1px solid #000;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
    background-color: #000;
    transition: all 0.3s;
}