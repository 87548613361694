
.ColumnsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.ColumnsDescription {
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  width: calc(100% - 40px);
  padding: 20px
}








.ColumnsPopupBackground {
  min-width: 100vw;
  min-height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(28, 37, 46, 0.5); 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

.ColumnsPopupBackgroundHidden {
  opacity: 0;
  visibility: hidden;
  max-width: 0px;
  max-height: 0px;
  display: none;
  transition: all 0.3s;   
}

.ColumnsPopupContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 800px;
  min-height: 500px;
  background-color: #1c252e;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) -40px 40px 80px -8px;
  transition: all 0.5s;
  position: relative;
  background-color: #f7f7f7;
}

.ColumnsPopupContainerBackIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 10px;
  border-radius: 30px;
  color: #888;
  cursor: pointer;
  transition: all 0.5s;
}

.ColumnsPopupContainerCloseIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 30px;
  color: #888;
  cursor: pointer;
  transition: all 0.5s;
}

.ColumnsPopupTitle {
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  width: calc(100% - 40px);
  padding: 20px

}



.ColumnsPopupTitleListName {
  display: inline-block;
  font-weight: 600;
  color: #00a76f;
}

.ColumnsPopupListNameInputLabel {
  display: block;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #666;
  margin-left: 10px;
}

.ColumnNamesContainerRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px;
  margin: 0px;
}

.ColumnsPopupListNameInputContainer {
  width: 60%;
}

.ColumnsPopupListNameInput {
  display: block;
  width: calc(100% - 40px);
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  color: #666;
  font-size: 14px;
  outline: none;
  transition: all 0.5s;
}

.ColumnsPopupNewCombinationButton {
  display: block;
  width: 110px;
  font-size: 12px;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  background-color: #00a76f;
  color: #fff;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
}


.ColumnNamesContainerScrollContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  margin: 0px;
  overflow: auto;
  max-height: 400px;
}

.ColumnNamesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 20px 0px 20px;

}

.ColumnNameSelected {
  display: block;
  user-select: none;
  margin: 5px;
  padding: 6px 10px 6px 10px;
  border-radius: 5px;
  font-size: 18px;
  color: #00a76f;
  background-color: #00a76f14;
  cursor: pointer;
  transition: all 0.5s;
}

.ColumnName {
  display: block;
  user-select: none;
  margin: 5px;
  padding: 6px 10px 6px 10px;
  border-radius: 5px;
  font-size: 18px;
  color: #666;
  background-color: #55555514;
  cursor: pointer;
  transition: all 0.5s;
}

.ColumnsPopupSubmitButton {
  display: block;
  position: absolute;
  bottom: 10px;
  right: 20px;
  text-align: center;
  padding: 10px;
  width: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0px solid #666;
  border-radius: 5px;
  color: #fff;
  background-color: #00a76f;
  outline: none;
  transition: all 0.5s;
  cursor: pointer;
}

