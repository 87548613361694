


.chart {
    border-radius: 10px;
    padding: 5px 5px 5px 5px;
    margin: 10px 20px 0px 20px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.00);
    border: 1px solid #e7e7e7;
    background-color: #f7f7f7;
}



.chartTitle {
    font-size: 16px;
    font-weight: 600;
    margin-left: 0px;
    margin-top: 10px;
    display: block;
    text-align: center;
    min-width: 100%;
}

.chartTotal {
    font-size: 20px;
    font-weight: 600;
    margin-left: 0px;
    display: block;

}