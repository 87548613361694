
.SuppressionContainer {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.SuppressionOptionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 700px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e7e7e7;
  background-color: #e7e7e760;
  position: relative;
  border-radius: 10px;
  color: #fff;
  transition: all 0.5s;

}

.SuppressionOutputFileNameInput {
  display: block;
  padding: 10px;
  margin-left: 10px;
  width: 900px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  color: #222;
  font-size: 16px;
  background-color: #f7f7f7;
  outline: none;
  transition: all 0.5s;


}

.SuppressionCheckboxRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5px;
  border-radius: 15px;
  color: #00a76f;
  transition: all 0.5s;
}

.SuppressionCheckboxTextContainer {
  display: block;
  width: fit-content;
  border: 0px solid #666;
  position: relative;
  color: #00a76f;
  transition: all 0.5s;
}

.SuppressionCheckboxText {
  user-select: none;
}

.SuppressionColumnsDropdownButton {
  padding: 5px;
  margin: 5px;
  width: fit-content;
  border: 1px solid #666;
  border-radius: 5px;
  color: #00a76f;
  background-color: #1c252e;
  outline: none;
  transition: all 0.5s;
}

.SuppressionOutputOptionsInput {
  width: 30px;
  margin: 5px;
  padding: 5px;
  border: 1px solid #666;
  border-radius: 5px;
  color: #00a76f;
  background-color: #1c252e;
  outline: none;
  transition: all 0.5s;
}

.SuppressionOptionsSubmitRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  border-radius: 15px;
  color: #00a76f;
  transition: all 0.5s;

}

.SuppressionOptionsSubmitButton {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 10px 10px 15px;
  margin: 5px 5px 5px 5px;
  width: 150px;
  border-radius: 25px;
  color: #fff;
  background-color: #00a76f;
  outline: none;
  cursor: pointer;
  transition: all 0.5s;
}

.SuppressionOptionsSubmitButtonDisabled {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  margin: 5px 5px 5px 5px;
  border: 0px solid #666;
  width: fit-content;
  border-radius: 25px;
  color: #fff;
  background-color: #1c252e;
  outline: none;
  cursor: not-allowed;
  transition: all 0.5s;
}

.SuppressionContainerDescription {
  display: block;
  padding: 10px;
  width: fit-content;
  margin-bottom: 10px;
  border: 0px solid #666;
  position: relative;
  border-radius: 15px;
  color: #00a76f;
  transition: all 0.5s;

}

.SuppressionBottomControlRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(40% - 40px);
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
  background-color: #1c252e;
  min-height: 30px;
  border-radius: 40px;
  transition: all 0.5s;
}

.SuppressionBottomControlRowHidden {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -200px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(40% - 40px);
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
  background-color: #1c252e;
  min-height: 30px;
  border-radius: 40px;
  transition: all 0.5s;
  pointer-events: none;
  transition: all 0.5s;
}

.SuppressionBottomControlRowText {
  display: block;
  padding: 10px;
}