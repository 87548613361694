

.TextInputFieldWrapper {
    position: relative;
    
}

.TextInputFieldText {
    display: block;
    position: absolute;
    padding: 0px 5px 0px 5px;
    background-color: #fff;
    top: 16px;
    left: 10px;
    font-size: 15px;
    color: #ccc;
    font-family: Arial, Helvetica, sans-serif;
    user-select: none;
    font-weight: 300;
    transition: all 0.15s ease;
    cursor: text;

}

.TextInputFieldInput:focus + .TextInputFieldText {
    display: block;
    position: absolute;
    padding: 0px 5px 0px 5px;
    background-color: #fff;
    top: -6px;
    left: 10px;
    font-size: 12px;
    color: #272727;
    user-select: none;
    font-weight: 500;
    transition: all 0.15s ease;
}

/* If input isnt empty */
.TextInputFieldTextNotEmpty {
    display: block;
    position: absolute;
    padding: 0px 5px 0px 5px;
    background-color: #fff;
    top: -6px;
    left: 10px;
    font-size: 12px;
    color: #272727;
    font-family: Arial, Helvetica, sans-serif;
    user-select: none;
    transition: all 0.15s ease;
}

.TextInputFieldInput {
    border: none;
    border-color: #ccc;
    background-color: #ffffff;
    border: 1px solid #e7e7e7;
    padding: 15px;
    margin-bottom: 0px; /* Adjusted margin */
    border-radius: 10px; /* Adjusted radius */
    font-size: 15px; /* New style */
    font-family: 'Avenir_Regular';
    color: #000;
    display: block;
    width: calc(100% - 28px);
    outline: none;
    transition: all 0.3s ease;
}

.TextInputFieldInput:focus {
    border: 1px solid #272727;
    outline: 1px solid #272727;;
}