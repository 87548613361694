

.AccountsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.AccountsLeftContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 500px;
    border-right: 1px solid #e7e7e7;
    min-height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
    
}

.AccountsLeftScrollContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow-y: auto;
    padding: 10px;
}

.AccountCardAddAccount {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: calc(90% - 40px);
    padding: 10px;
    border-radius: 10px;
    color: #666;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 0.5s;
}

.AccountCardAddAccount:hover {
    background-color: #f0f0f0;
}

.AccountsLeftFilter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: calc(90% - 20px);
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;
    color: #666;
    cursor: pointer;
    transition: all 0.5s;
}

.AccountsLeftFilterInput {
    display: block;
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    border: 0px solid #666;
    background-color: #e1e5ea;
    outline: none;
    transition: all 0.5s;
}

.AccountsLeftFilterSortByContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    height: 40px;
    margin-left: 5px;
    border-radius: 40px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    color: #666;
    cursor: pointer;
    transition: all 0.5s;
}

.AccountCard {
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    color: #666;
    width: calc(90% - 40px);
    margin-top: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    transition: all 0.5s;
}

.AccountCardURL {
    display: block;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 14px;
    outline: none;
    transition: all 0.5s;
}

.AccountCardSpeed {
    display: block;
    position: absolute;
    right: 7px;
    top: 7px;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 12px;
    outline: none;
    color:#00a76f;
    background-color: #00a76f14;
    transition: all 0.5s;
}

.AccountCardUsername {
    display: block;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 12px;
    outline: none;
    transition: all 0.5s;
}

.AccountCardPassword {
    display: block;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 12px;
    outline: none;
    transition: all 0.5s;
}

.AccountsRightContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.AccountsRightHeader {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}

.AccountsRightHeaderIconRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 10px;
    margin-bottom: 10px;
    color: #fff;
    background-color: #f7f7f7;
    border-radius: 40px;
    transition: all 0.5s;
}

.AccountsRightHeaderIconContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    margin-right: 10px;
    cursor: pointer;
    transition: all 0.5s;
}

.AccountsRightHeaderTitle {
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #666;
    margin-right: 10px;
}

.AccountsRightHeaderSubtitle {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #666;
}

.AccountsMetricsBottomRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: calc(40% - 40px);
    padding: 10px;
    margin-bottom: 20px;
    color: #fff;
    background-color: #1c252e;
    min-height: 30px;
    border-radius: 40px;
    transition: all 0.5s;
}

.AccountsRightFilesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.AccountsRightFileCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 500px;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.00);
    border: 1px solid #666;
    cursor: pointer;
    transition: all 0.5s;
}

.AccountsRightFileCardName {
    display: block;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 12px;
    color: #fff;
    outline: none;
    transition: all 0.5s;
}

.AccountsFileListEmptyText {
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #aaa;
    margin-top: 300px;
}


.AccountsCreateAccountBackground {
    min-width: 100vw;
    min-height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(28, 37, 46, 0.5); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
}

.AccountsCreateAccountBackgroundHidden {
    opacity: 0;
    visibility: hidden;
    max-width: 0px;
    max-height: 0px;
    display: none;
    transition: all 0.3s;  
}

.AccountsCreateAccountContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 800px;
    min-height: 500px;
    background-color: #1c252e;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) -40px 40px 80px -8px;
    transition: all 0.5s;
    position: relative;
    background-color: #f7f7f7;

}

.AccountsCreateAccountContainerCloseIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    border-radius: 30px;
    color: #888;
    cursor: pointer;
    transition: all 0.5s;
}

.AccountsCreateAccountPopupTitle {
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #666;
    margin-top: 20px;
    margin-bottom: 20px;
}

.AccountsCreateAccountInputCountainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 60%;

}

.AccountsCreateAccountInputLabel {
    display: block;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #666;
    margin-left: 10px;
}

.AccountsCreateAccountInput {
    display: block;
    padding: 10px;
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    color: #272727;
    background-color: #fff;
    outline: none;
    transition: all 0.5s;
}

.AccountsCreateAccountButton {
    display: block;
    text-align: center;
    padding: 10px;
    width: 200px;
    margin-bottom: 10px;
    border: 0px solid #666;
    border-radius: 5px;
    color: #fff;
    background-color: #00a76f;
    outline: none;
    transition: all 0.5s;
    cursor: pointer;
}













.AccountsPushToSendyBackground {
    min-width: 100vw;
    min-height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(28, 37, 46, 0.5); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
}

.AccountsPushToSendyBackgroundHidden {
    opacity: 0;
    visibility: hidden;
    max-width: 0px;
    max-height: 0px;
    display: none;
    transition: all 0.3s;   
}

.AccountsPushToSendyContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 800px;
    min-height: 500px;
    background-color: #1c252e;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) -40px 40px 80px -8px;
    transition: all 0.5s;
    position: relative;
    background-color: #f7f7f7;
}

.AccountsPushToSendyContainerCloseIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    border-radius: 30px;
    color: #888;
    cursor: pointer;
    transition: all 0.5s;
}

.AccountsPushToSendyTitle {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    width: calc(100% - 40px);
    padding: 20px

}

.AccountsPushToSendyListPresetRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e7e7e7;
    background-color: #fff;;
    width: calc(100% - 140px);
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    border-radius: 10px;
    color: #666;
    cursor: pointer;
    transition: all 0.5s;
}

.AccountsPushToSendyListPresetRowOptionSelected {
    display: block;
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 12px;
    outline: none;
    transition: all 0.5s;
    background-color: #00a76f14;
    cursor: pointer;
    user-select: none;
    color:#00a76f;
}

.AccountsPushToSendyListPresetRowOption {
    display: block;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 12px;
    outline: none;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;
}

.AccountsPushToSendyListRangeInputRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 100px);
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    border-radius: 10px;
    color: #666;
    cursor: pointer;
    transition: all 0.5s;
}

.AccountsPushToSendyTitleListName {
    display: inline-block;
    font-weight: 600;
    color: #00a76f;
}

.AccountsPushToSendyListNameInputLabel {
    display: block;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #666;
    margin-left: 10px;
}

.AccountsPushToSendyListNameInputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 60%;
}

.AccountsPushToSendyListNameInput {
    display: block;
    width: calc(100% - 40px);
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    color: #666;
    font-size: 14px;
    outline: none;
    transition: all 0.5s;
}

.AccountsPushToSendySuppContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 90%;
}

.AccountsPushToSendySuppSelected {
    display: block;
    padding: 10px 15px;
    margin: 5px;
    border-radius: 10px;
    font-size: 12px;
    outline: none;
    transition: all 0.5s;
    background-color: #00a76f14;
    cursor: pointer;
    user-select: none;
    color:#00a76f;
}

.AccountsPushToSendySupp {
    display: block;
    padding: 10px 15px;
    margin: 5px;
    border-radius: 5px;
    font-size: 12px;
    outline: none;
    background-color: #66666614;
    color: #666;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;
}

.AccountsPushToSendySubmitButton {
    display: block;
    text-align: center;
    padding: 10px;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0px solid #666;
    border-radius: 5px;
    color: #fff;
    background-color: #00a76f;
    outline: none;
    transition: all 0.5s;
    cursor: pointer;
}

.AccountsPushToSendyAccountRowContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    max-height: 500px;
    overflow-y: auto;
}

.AccountsPushToSendyAccountRowSelected {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 140px);
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    background-color: #f7f7f7;
    color: #666;
    cursor: pointer;
    transition: all 0.5s;
}

.AccountsPushToSendyAccountRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 140px);
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    color: #666;
    cursor: pointer;
    transition: all 0.5s;
}

















.AccountsRenamePopupBackground {
    min-width: 100vw;
    min-height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(28, 37, 46, 0.5); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
}

.AccountsRenamePopupBackgroundHidden {
    opacity: 0;
    visibility: hidden;
    max-width: 0px;
    max-height: 0px;
    display: none;
    transition: all 0.3s;   
}

.AccountsRenamePopupContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    min-height: 250px;
    background-color: #1c252e;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) -40px 40px 80px -8px;
    transition: all 0.5s;
    position: relative;
    background-color: #f7f7f7;
}

.AccountsRenamePopupContainerCloseIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    border-radius: 30px;
    color: #888;
    cursor: pointer;
    transition: all 0.5s;
}

.AccountsRenamePopupTitle {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #666;
    width: calc(100% - 40px);
    padding: 20px

}

.AccountsRenamePopupListNameInput {
    display: block;
    width: calc(100% - 40px);
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    color: #666;
    font-size: 14px;
    outline: none;
    transition: all 0.5s;
}

.AccountsRenamePopupSubmitButton {
    display: block;
    text-align: center;
    padding: 10px;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0px solid #666;
    border-radius: 10px;
    color: #fff;
    background-color: #00a76f;
    outline: none;
    transition: all 0.5s;
    cursor: pointer;
}

.AccountsDeletePopupSubmitButton {
    display: block;
    text-align: center;
    padding: 10px;
    width: 200px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0px solid #666;
    border-radius: 10px;
    color: #fff;
    background-color: #e20000;
    outline: none;
    transition: all 0.5s;
    cursor: pointer;
}