
.CountContainer {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}

.CountBottomControlRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(40% - 80px);
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
  background-color: #1c252e;
  min-height: 30px;
  border-radius: 40px;
  transition: all 0.5s;
}

.CountBottomControlRowHidden {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -200px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(40% - 40px);
  padding: 10px;
  margin-bottom: 20px;
  color: #fff;
  background-color: #1c252e;
  min-height: 30px;
  border-radius: 40px;
  transition: all 0.5s;
  pointer-events: none;
}

.CountBottomControlRowTextColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;

}

.CountBottomControlRowText {
  display: block;
  padding: 0px 10px 0px 10px;
}

.CountBottomControlRowDescriptionText {
  display: block;
  padding: 0px 10px 0px 10px;
  color: #00a76f;
  font-size: 10px;
}

.CountBottomControlRowTextButton {
  display: block;
  position: absolute;
  right: 15px;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
  background-color: #00a76f14;
  color: #00a76f;
  border-radius: 30px;
  transition: all 0.3s;
}