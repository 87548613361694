




.dashboardContainer {
    width: 90%;

}

.dashboardTopGridView {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
}

.dashboardTopGridViewCard {

    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    color: #666;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
    transition: all 0.5s;
}

.dashboardTopGridViewCardTitle {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-top: 10px;
}

.dashboardTopGridViewCardValue {
    font-size: 30px;
    font-weight: 600;
    color: #333;
    margin-top: 10px;
}

.dashboardTopGridViewCardProgressBarParent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    background-color: #00a76f14;
    border-radius: 10px;

}

.dashboardTopGridViewCardProgressBarChild {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10px;
    background-color: #00a76f;
    border-radius: 5px;
    transition: all 0.5s;
}


.dashboardMiddleGridView {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
    height: 100%;
}

.dashboardMiddleGridViewSection {
    padding: 20px;
    border-radius: 10px;
    color: #666;
    transition: all 0.5s;
}

.dashboardMiddleGridViewSectionTitle {
    font-size: 16px;
    font-weight: 600;
    color: #666;
    margin-top: 10px;
    margin-bottom: 20px;
    display: block;
    text-align: center;
}

.dashboardMiddleGridViewSectionCard {
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    color: #666;
    margin-top: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
    position: relative;
    transition: all 0.5s;
}

.dashboardMiddleGridViewSectionCardName {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-top: 0px;
}

.dashboardMiddleGridViewSectionCardDescription {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: #666;
    margin-top: 10px;
}

.dashboardMiddleGridViewSectionCardStatus {
    font-size: 14px;
    font-weight: 600;
    color: #666;
    margin-top: 10px;
    display: block;
    position: absolute;
    top: 0px;
    right: 15px;

}

.dashboardMiddleGridViewSectionCardStartTime {
    font-size: 14px;
    font-weight: 600;
    color: #666;
    margin-top: 10px;
    display: block;

}

.dashboardMiddleGridViewSectionCardEndTime {
    font-size: 14px;
    font-weight: 600;
    color: #666;
    margin-top: 10px;
    display: block;

}