
@font-face {
  font-family: 'Avenir_Heavy';
  src: url('./assets/fonts/Avenir_Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir_Regular';
  src: url('./assets/fonts/Avenir_Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}





:root {
  --dark-background-color: #141a21f5;
  --light-background-color: #f7f7f7;
  --dark-card-background: #1c252e;
}

.darkMode {
  background-color: var(--dark-background-color) !important;
}

.darkModeCard {
  background-color: var(--dark-card-background) !important;
  border: 0px solid #e7e7e7 !important;
  color: #fff !important;
}

.darkModeCardText {
  color: #fff !important;
}







input {
  font-family: 'Avenir_Regular';
}


.loginContainer {
  min-height: 100vh;
  font-family: 'Avenir_Heavy';
}

.loginLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: 
  linear-gradient(rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)) center center / cover no-repeat,
  url("./assets/OmniLoginBackgroundBlur.png") center center / cover no-repeat;
  background-position: center center;
  min-height: 100vh;
  width: calc(100vw - 50px)
}

.loginLeftLogo {
  position: fixed;
  top: 40px;
  left: 40px;
  font-size: 24px;
}

.loginWelcomeText {
  padding-bottom: 60px;
  font-size: 20px;
}

.loginRight {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 350px;
  min-height: 100vh;
  background-color: white;
  padding: 120px 60px 60px 60px;
}

.loginRightText {
  color: black;
  display: block;
  font-size: 24px;
  padding-bottom: 5px;
  padding-top: 20px;
}

.loginRightCreateText {
  color: #666;
  display: block;
  font-size: 14px;
  padding-bottom: 40px;
}

.loginRightCreateLink {
  display: inline-block;
  color: #00A76F;
  cursor: pointer;
  transition: all 0.5s;
}

.loginRightCreateLink:hover {
  text-decoration: underline;
}

.loginInput {
  border: none;
  border-color: #ccc;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
  padding: 15px;
  margin-bottom: 20px; /* Adjusted margin */
  border-radius: 15px; /* Adjusted radius */
  font-size: 15px; /* New style */
  font-family: 'Avenir_Regular';
  color: #666;
  display: block;
  width: calc(100% - 28px);
  outline: none;
}


.buttonContainer {
  background-color: #272727;
  border-radius: 20px;
  border: none;
  color: white;
  width: 100%;
  padding: 15px;
  align-items: center;
  margin-bottom: 25px;
  font-family: 'Avenir_Heavy';
  box-shadow: 0px 20px 40px rgba(147, 91, 231, 0.0);
  margin-top: 30px;
  cursor: pointer;
}

.facebookButtonContainer {
  background-color: #387ce4;
  border-radius: 20px;
  border: none;
  color: white;
  width: 60%;
  padding: 15px;
  align-items: center;
  margin-bottom: 25px;
  font-family: 'Avenir_Heavy';
  box-shadow: 0px 20px 40px rgba(56, 124, 228, 0.3);
  margin-top: 30px;
  cursor: pointer;
}



.buttonContainer:hover {
  
}