


.DateSelectorBackground {
    padding: 0px;
    border-radius: 5px;
    margin: 0px;
}

.DateSelectorCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.DateSelectorPresetContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    width: 100px;

}

.DateSelectorPresetSelected {
    display: block;
    background-color: #e7e7e7;
    padding: 3px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    user-select: none;
}

.DateSelectorPreset {
    display: block;
    padding: 3px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    user-select: none;
}

.DateSelectorPreset:hover {
    background-color: #e7e7e7;
}


.DateSelectorsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.DateSelectorSubmitButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 10px 10px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.DateSelectorSubmitButton {
    display: block;
    background-color: #222;
    color: white;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
}