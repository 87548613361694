


.navContainer {
    display: flex;
    flex-direction: row;
    background-color: var(--light-background-color);
    font-family: "Avenir_Heavy";
    font-size: 14px;
    margin: 0px !important;
}

.navLeftContainer {
    min-height: 100vh;
    min-width: 280px;
    max-width: 280px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px dashed #e7e7e7;
    background-image: url('https://minimals.cc/assets/cyan-blur.png'), url('https://minimals.cc/assets/red-blur.png');
    background-repeat: no-repeat, no-repeat;
    background-size: 50%, 50%;
    background-position: right top, left bottom;
    transition: all 0.5s;
    position: relative;
    z-index: 3;
}

.navLeftContainerCollapsed {
    min-height: 100vh;
    min-width: 100px;
    max-width: 100px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px dashed #e7e7e7;
    background-image: url('https://minimals.cc/assets/cyan-blur.png'), url('https://minimals.cc/assets/red-blur.png');
    background-repeat: no-repeat, no-repeat;
    background-size: 50%, 50%;
    background-position: right top, left bottom;
    transition: all 0.5s;
    position: relative;
    z-index: 4;
}

.navExpandCollapseIconContainer {
    position: absolute;
    top: 40px;
    right: -14px;
    background-color: var(--light-background-color);
    border-radius: 30px;
    width: 28px;
    height: 28px;
    color: #888;
    border: 1px dashed #e7e7e7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s;
    z-index: 4;
}

.navLeftContainerTop {

}

.navLeftContainerBottom {

}

.navLeftContainerTitle {
    font-size: 25px;
    display: block;
    height: fit-content;
    text-align: center;
    padding: 20px 20px 10px 20px;
    user-select: none;
    transition: all 0.5s;

}

.navLeftContainerSubtitle {
    display: block;
    color: #919EAB;
    font-size: 12px;
    text-transform: uppercase;
    padding-left: 30px;
    margin-top: 10px;
}

.navLeftContainerItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 900;
    border: 0px solid #e7e7e7;
    margin: 5px 15px 5px 15px;
    border-radius: 10px;
    color: #637381;
    background-color: #ffffff00;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s, box-shadow 0.5s;

}
.navLeftContainerItem:hover {
    background-color: #f4f4f4;
}

.navLeftContainerItemSelected {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 900;
    box-shadow: 0px 30px 30px rgba(147, 91, 231, 0.0);
    margin: 5px 15px 5px 15px;
    border-radius: 10px;
    color: #00a76f;
    background-color: #00a76f14;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    user-select: none;
}

.navLeftContainerItemLeft {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px 12px 15px;
    transition: all 0.5s;
}

.navLeftContainerItemLeftCollapsed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    text-align: center;
    width: 70px;
    height: 60px;
    transition: all 0.5s;

}

.navLeftContainerItemLeftTextCollapsed {

}

.navLeftContainerItemLeftText {
    display: block;
    padding-left: 15px;
    
}

.navLeftContainerItemSelected {

}

.navRightContainerCollapsed {
    background-color: var(--light-background-color);
    min-width: calc(100vw - 120px);
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    max-height: calc(100vh - 70px);
    overflow: auto;
    transition: all 0.5s;
}

.navRightContainer {
    background-color: var(--light-background-color);
    min-width: calc(100vw - 300px);
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    max-height: calc(100vh - 70px);
    overflow: auto;
    transition: all 0.5s;

}

.navNavbarContainer {
    box-shadow: 0px 0px 12px rgba(147, 91, 231, 0.0);
    border-bottom: 0px solid #e7e7e7;
    min-width: calc(100vw - 281px);
    min-height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    right: 0;
    backdrop-filter: blur(7px);
    background-color: var(--light-background-color)ac;
    transition: all 0.5s;
    z-index: 2;

}

.navNavbarContainerCollapsed {
    box-shadow: 0px 0px 12px rgba(147, 91, 231, 0.0);
    border-bottom: 0px solid #e7e7e7;
    min-width: calc(100vw - 101px);
    min-height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    right: 0;
    backdrop-filter: blur(7px);
    background-color: var(--light-background-color)ac;
    transition: all 0.5s;
    z-index: 3;

}

.navNavbarLeftContainer {
    margin-left: 40px;

}

.navNavbarLeftSearchContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.navNavbarLeftSearchIcon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #637381;
}

.navNavbarLeftSearchKeysText {
    display: block;
    color: #637381;
    background-color: #919eab29;
    padding: 3px 6px 3px 6px;
    border-radius: 10px;
    margin-bottom: 7px;
    margin-left: 5px;
    font-size: 12px;
    user-select: none;
}

.navNavbarRightContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #888;
}

.navNavbarRightContainerIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 30px;
    margin: 5px;
    cursor: pointer;
    transition: all 0.5s;

}

.navNavbarRightContainerIcon:hover {
    background-color: #ddd
}

.navNavbarRightContainerSettingsIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 30px;
    margin: 5px;
    cursor: pointer;
    transition: all 0.5s;
    animation: spin 15.0s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.navNavbarRightContainerSettingsIcon:hover {
    background-color: #f4f4f4
}

.navNavbarRightContainerAvatarContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 30px;
    padding: 3px;
    border: 1px solid #eaeaea;
    border-radius: 30px;
    margin-right: 40px;
    margin-left: 10px;
    cursor: pointer;
    transition: all 0.5s;
    position: relative;
}

.navNavbarRightContainerAvatarText {
    display: block;
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    color: #888;
    margin-left: 0px;
    user-select: none;
}

.navNavbarProfileDropdown {
    position: absolute;
    top: 60px;
    right: 30px;
    background-image: url('https://minimals.cc/assets/cyan-blur.png'), url('https://minimals.cc/assets/red-blur.png');
    background-repeat: no-repeat, no-repeat;
    background-size: 50%, 50%;
    background-position: right top, left bottom;
    box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px;
    border-radius: 15px;
    width: 200px;
    overflow: hidden;
    background-color: #fff;
}

.navNavbarProfileDropdownSection1 {
    border-bottom: 1px dashed #e7e7e7;
    padding: 15px;
    
}

.navNavbarProfileDropdownSection1Name {
    display: block;
    color: #272727;
}

.navNavbarProfileDropdownSection1Email {
    display: block;
    font-size: 12px;
}

.navNavbarProfileDropdownSection2 {
    border-bottom: 1px dashed #e7e7e7;
    z-index: 1000;

}

.navNavbarProfileDropdownSection2Link {
    display: block;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    margin: 5px;
    border-radius: 10px;
    color: #494949;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;
}

.navNavbarProfileDropdownSection2Link:hover {
    background-color: #f4f4f4;
}

.navNavbarProfileDropdownSection3 {
}

.navNavbarProfileDropdownSection3LogoutButton {
    display: block;
    padding: 5px 10px 5px 10px;
    margin: 5px;
    border-radius: 10px;
    color: #ff5630;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;

}

.navNavbarProfileDropdownSection3LogoutButton:hover {
    background-color: #f4f4f4;
}

.navNavbarDateSelector {
    border: 1px solid #e7e7e7;
    width: fit-content;
    padding: 3px 10px 3px 10px;
    border-radius: 6px;
    margin: 0px 5px 0px 5px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.3s, box-shadow 0.5s, border 0.5s;

}
.navNavbarDateSelector:hover {
    transition: background-color 0.3s, box-shadow 0.5s, border 0.5s;
    box-shadow: 0px 0px 20px rgba(147, 91, 231, 0.15);
    border: 1px solid #e7e7e7;
    z-index: 20;


}

.navNavbarDateSelectorText {
    font-weight: 500;
    font-family: 'Avenir_Regular';
}


.navContentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--light-background-color);
}




.navUploadFAB {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    background-color: #00a76f30;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 30px #00a76f40;
    cursor: pointer;
    transition: all 0.5s;
    z-index: 1000000;

}

.navSearchPopupBackground {
    min-width: 100vw;
    min-height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(28, 37, 46, 0.5); 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 120px;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
}

.navSearchPopupBackgroundHidden {
    opacity: 0;
    visibility: hidden;
    max-width: 0px;
    max-height: 0px;
    display: none;
    transition: all 0.3s;   
}

.navSearchPopup {
    width: 600px;
    min-height: 500px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) -40px 40px 80px -8px;
    transition: all 0.5s;

}

.navSearchTopContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px dashed #e7e7e7;

}

.navSearchTopSearchIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 30px;
    color: #888;
    cursor: pointer;
    transition: all 0.5s;

}

.navSearchTopInput {
    width: 100%;
    padding: 10px;
    border: 0px solid #e7e7e7;
    border-radius: 10px;
    margin-left: 0px;
    font-size: 18px;
    outline: 0px;
    color: #637381;
    transition: all 0.5s;

}

.navSearchTopESCButton {
    display: block;
    color: #637381;
    background-color: #919eab29;
    padding: 3px 6px 3px 6px;
    border-radius: 10px;
    margin-left: 5px;
    font-size: 12px;
    cursor: pointer;
    user-select: none;

}

.navSearchResultsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 20px 20px 20px;
    overflow: auto;
    max-height: 400px;
    transition: all 0.5s;
}

.navSearchResultsCategory {
    display: block;
    padding-left: 10px;
    font-size: 16px;
    color: #444;
    margin-top: 20px;
    font-weight: 800;
    text-transform: uppercase;

}

.navSearchResult {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    border-top: 1px dashed #fff;
    border-left: 1px dashed #fff;
    border-right: 1px dashed #fff;
    border-bottom: 1px dashed #e7e7e7;
    border-radius: 10px;
    width: calc(100% - 20px);
    cursor: pointer;
    transition: all 0.5s;
}

.navSearchResult:hover {
    color: #00a76f;
    background-color: #00a76f14;
    border: 1px dashed #00a76f;

}

.navSearchResultTitle {
    display: block;
    font-size: 14px;
    color: #637381;

}

.navSearchResultDescription {
    display: block;
    font-size: 12px;
    color: #919eab;
}

.navSearchResultLinkRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    border-radius: 10px;
    width: calc(100% - 10px);
    cursor: pointer;
    transition: all 0.5s;
}

.navSearchResultLinkRowLink {
    display: block;
    font-size: 12px;
    background-color: #66666614;
    color:#666;
    text-decoration: none;
    padding: 3px 6px 3px 6px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.5s;
}

.navSearchResultLinkRowLink:hover {
    background-color: #00a76f14;
    color:#00a76f;
}








.navUploadPopupBackground {
    min-width: 100vw;
    min-height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(28, 37, 46, 0.5); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
}

.navUploadPopupBackgroundHidden {
    opacity: 0;
    visibility: hidden;
    max-width: 0px;
    max-height: 0px;
    display: none;
    transition: all 0.3s;   
}

.navUploadPopup {
    width: 800px;
    min-height: 500px;
    background-color: #f7f7f7;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) -40px 40px 80px -8px;
    transition: all 0.5s;
    position: relative;
}

.navUploadPopupCloseIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    border-radius: 30px;
    color: #888;
    cursor: pointer;
    transition: all 0.5s;
}

.navUploadPopupStep {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 500px;
}

.navUploadHeaderText {
    display: block;
    font-size: 16px;
    color: #666;
    padding: 20px 0px 20px 20px;

}

.navUploadColumnNamesContainerScrollContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    margin: 0px;
    overflow: auto;
    max-height: 400px;
}

.navUploadColumnNamesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    margin-bottom: 70px;

}

.navUploadColumnNameSelected {
    display: block;
    margin: 5px;
    padding: 6px 10px 6px 10px;
    border-radius: 5px;
    font-size: 18px;
    color: #00a76f;
    background-color: #00a76f14;
    cursor: pointer;
    transition: all 0.5s;
}

.navUploadColumnName {
    display: block;
    margin: 5px;
    padding: 6px 10px 6px 10px;
    border-radius: 5px;
    font-size: 18px;
    color: #666;
    background-color: #55555514;
    cursor: pointer;
    transition: all 0.5s;

}

.navUploadPopupButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.navUploadPopupButton {
    display: block;
    text-align: center;
    color: #fff;
    background-color: #00a76f;
    padding: 7px 15px 7px 15px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    width: 150px;
    cursor: pointer;
    transition: all 0.3s;
}






.navNotificationRightPane {
    margin-top: 0px;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 400px;
    max-width: 400px;
    background-image: url('https://minimals.cc/assets/cyan-blur.png'), url('https://minimals.cc/assets/red-blur.png');
    background-repeat: no-repeat, no-repeat;
    background-size: 50%, 50%;
    background-position: right top, left bottom;
    backdrop-filter: blur(55px);
    background-color: #e7e7e760;
    border-left: 1px dashed #e7e7e7;
    position: fixed; /* changed to fixed */
    top: 0;
    right: -700px; /* initially hide it off-screen */
    padding: 0;
    border-radius: 0;
    z-index: 1000000;
    outline: none;
    overflow: none;
    transition: right 0.3s ease; /* added transition */
}

.navNotificationRightPane.open {
    right: 0; /* slide it in when open */
    box-shadow: 0px 0px 0px #39393940;

}

.navNotificationRightPaneHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid #ddd;
}

.navNotificationRightPaneHeaderCollapseIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.5s;
}

.navNotificationRightPaneHeaderCollapseIcon:hover {
    background-color: #ddd;
}

.navNotificationRightPaneHeaderTitle {
    display: block;
    font-size: 20px;
    color: #666;
}

.navNotificationRightPaneContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px 20px 20px;
    overflow: auto;
    max-height: calc(100vh - 140px);
}

.navNotificationRightPaneContentSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    width: 90%;
    cursor: pointer;
    transition: all 0.5s;
}

.navNotificationRightPaneContentSectionTitle {
    display: block;
    text-align: left;
    margin-bottom: 6px;
    font-size: 20px;
    color: #666;
}

.navNotificationRightPaneContentSectionItem {
    display: block;
    position: relative;
    font-size: 14px;
    color: #666;
    background-color: #f7f7f7;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    margin-top: 10px;
}

.navNotificationRightPaneContentSectionItemProgress {
    position: absolute;
    top: 10px;
    right: 10px;
}

.navNotificationRightPaneContentSectionItemTitle {
    display: block;
    font-size: 18px;
    color: #666;
    font-weight: 600;
}

.navNotificationRightPaneContentSectionItemDescription {
    display: block;
    font-size: 12px;
    color: #666;
}




.navSelectionRightPane {
    margin-top: 0px;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 400px;
    max-width: 400px;
    background-image: url('https://minimals.cc/assets/cyan-blur.png'), url('https://minimals.cc/assets/red-blur.png');
    background-repeat: no-repeat, no-repeat;
    background-size: 50%, 50%;
    background-position: right top, left bottom;
    backdrop-filter: blur(55px);
    background-color: #e7e7e760;
    border-left: 1px dashed #e7e7e7;
    position: fixed; /* changed to fixed */
    top: 0;
    right: -700px; /* initially hide it off-screen */
    padding: 0;
    border-radius: 0;
    z-index: 1000000;
    outline: none;
    overflow: none;
    transition: right 0.3s ease; /* added transition */
}

.navSelectionRightPane.open {
    right: 0; /* slide it in when open */
    box-shadow: 0px 0px 0px #39393940;

}

.navSelectionRightPaneHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid #e7e7e7;
}

.navSelectionRightPaneHeaderCollapseIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.5s;
}

.navSelectionRightPaneHeaderCollapseIcon:hover {
    background-color: #ddd;
}

.navSelectionRightPaneHeaderTitle {
    display: block;
    font-size: 20px;
    color: #666;
}

.navSelectionRightPaneContentFileName {
    display: block;
    font-size: 16px;
    width: 100%;
    text-align: center;
    color: #444;
    padding-top: 10px;}

.navSelectionRightPaneContentDetailsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    margin: 10px 20px 5px 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
}

.navSelectionRightPaneContentDetailsText {
    display: block;
    font-size: 14px;
    color: #666;
}

.navSelectionRightPaneContentDetailsAmount {
    display: block;
    font-size: 14px;
    color: #00a76f;
}

.navSelectionRightPaneContentDetailsCalculateButton {
    display: block;
    font-size: 14px;
    color: #666;
    border-radius: 5px;
    border: 1px solid #666;
    padding: 0px 5px;
    cursor: pointer;
}





.navSignUpPopupContainer {
    min-width: 100vw;
    min-height: 100vh;
    backdrop-filter: blur(7px);
    background-color: #eaeaeaac;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

}

.navSignUpPopupContainer.open {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;

}

.navSignUpPopup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    min-height: 500px;
    max-height: 500px;
    min-width: 750px;
    max-width: 750px;
    background-color: #fff;
    border-radius: 20px;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    border: 1px solid #e1e1e1;
    z-index: 1000000;
    outline: none;
    overflow: hidden;
    transition: right 0.3s ease;
}

.navSignUpPopupTitle {
    display: block;
    width: 90%;
    text-align: left;
    padding: 20px;
    font-size: 18px;
}

.navSignUpPopupContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding: 20px;

}

.navIntegrations {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e7e7e7;
    padding: 5px;
    border-radius: 40px;
    width: 60%;
    cursor: pointer;
    user-select: none;
    margin: 10px;
}

.navIntegrationsPlatformImg {
    width: 40px;

}

.navIntegrationsPlatformText {
    display: block;
    width: calc(100% - 40px);
    text-align: center;
}

.navSignUpPopupFooter {
    border-top: 1px solid #e7e7e7;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.navSignUpPopupCancelButton {
    display: block;
    color: #000;
    padding: 7px 15px 7px 15px;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.3s;

}

.navSignUpPopupContinueButton {
    display: block;
    text-align: center;
    color: #fff;
    padding: 7px 0px 7px 0px;
    width: 80px;
    border: 1px solid #000;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
    background-color: #000;
    transition: all 0.3s;
}
