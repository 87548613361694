
.FileListContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 100px;
    width: 100%;

}

.FileListDate {
    display: block;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin: 10px;
}

.FileListFilterRowContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
    border: 0px dashed #e7e7e7;
    border-radius: 15px;
    color: #fff;
    transition: all 0.5s;
}

.FileListFilterRowLeft {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 552px;
    padding: 0px 0px;
    margin-bottom: 10px;
    position: relative;
    background-color: #1c252e;
    border: 0px dashed #e7e7e7;
    border-radius: 15px;
    color: #fff;
    transition: all 0.5s;
}

.FileListFilterLeftButtonSelected {
    display: block;
    padding: 10px;
    margin: 5px;
    min-width: 200px;
    text-align: center;
    border-radius: 10px;
    color: #00a76f;
    cursor: pointer;
    user-select: none;
    background-color: #00a76f14;
    transition: all 0.5s;
}

.FileListFilterLeftButton {
    display: block;
    padding: 10px;
    margin: 5px;
    min-width: 100px;
    text-align: center;
    border-radius: 10px;
    color: #666;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;
}

.FileListFilterRowRight {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    margin-left: 12px;
    padding: 0px 0px;
    margin-bottom: 10px;
    position: relative;
    background-color: #1c252e;
    border: 0px dashed #e7e7e7;
    border-radius: 15px;
    color: #fff;
    transition: all 0.5s;
}

.FileListFilterButtonRightSelected {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 5px;
    min-width: 40px;
    text-align: center;
    border-radius: 10px;
    color: #00a76f;
    cursor: pointer;
    user-select: none;
    background-color: #00a76f14;
    transition: all 0.5s;
}

.FileListFilterRightButton {
    display: block;
    padding: 10px;
    margin: 5px;
    min-width: 40px;
    text-align: center;
    border-radius: 10px;
    color: #666;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;
}

.FileListRowDownloadIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 1;
    cursor: pointer;
    background-color: #6666661b;
    min-width: 40px;
    margin-left: 10px;
    height: 30px;
    border-radius: 30px;
    pointer-events: all;
    transition: all 0.3s;

}

.FileListItemRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 700px;
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid #e7e7e7;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.0);
    position: relative;
    /* background-color: #1c252e; */
    border-radius: 15px;
    background-color: #fff;
    transition: all 0.5s;

}

.FileListItemRow:hover .FileListRowMoreIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 1;
    cursor: pointer;
    position: relative;
    background-color: #6666661b;
    min-width: 30px;
    height: 30px;
    border-radius: 30px;
    pointer-events: all;
    transition: all 0.3s;
}

.FileListRowMoreIcon {
    opacity: 0;
    z-index: 10;
    min-width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 5px;
    pointer-events: none;
}

.FileListItemRow:hover .FileListRowSendIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    opacity: 1;
    cursor: pointer;
    position: relative;
    background-color: #00a76f14;
    min-width: 30px;
    height: 30px;
    border-radius: 30px;
    pointer-events: all;
    transition: all 0.3s;
}

.FileListRowSendIcon {
    opacity: 0;
    z-index: 10;
    min-width: 30px;
    height: 30px;
    margin-left: 0px;
    margin-right: 5px;
    pointer-events: none;
}

.FileListRowDropdown {
    position: absolute;
    top: 40px;
    left: 0px;
    background-image: url('https://minimals.cc/assets/cyan-blur.png'), url('https://minimals.cc/assets/red-blur.png');
    background-repeat: no-repeat, no-repeat;
    background-size: 50%, 50%;
    background-position: right top, left bottom;
    box-shadow: rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px;
    border-radius: 15px;
    width: 200px;
    overflow: hidden;
    background-color: #fff;
}

.FileListRowDropdownSection1Name {
    display: block;
    color: #272727;
}

.FileListRowDropdownSection1Email {
    display: block;
    font-size: 12px;
}

.FileListRowDropdownSection2 {
    border-bottom: 1px dashed #e7e7e7;
    z-index: 1000;

}

.FileListRowDropdownSection2Link {
    display: block;
    font-size: 15px;
    padding: 5px 10px 5px 10px;
    margin: 5px;
    border-radius: 10px;
    color: #494949;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;
}

.FileListRowDropdownSection2Link:hover {
    background-color: #f4f4f4;
}

.FileListRowDropdownSection3 {
}

.FileListRowDropdownSection3LogoutButton {
    display: block;
    padding: 5px 10px 5px 10px;
    margin: 5px;
    border-radius: 10px;
    color: #ff5630;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s;

}

.FileListRowDropdownSection3LogoutButton:hover {
    background-color: #f4f4f4;
}




.FileListItemRowLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

}

.FileListRowName {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: #666;

}

.FileListRowExtension {
    display: block;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 5px 2px 5px;
    color: #00a76f;
    background-color: #00a76f14;
    margin: 0px 10px 0px 10px;
    border-radius: 5px;
    text-transform: uppercase;
}

.FileListRowExtensionSegment {
    display: block;
    font-size: 12px;
    font-weight: 600;
    padding: 3px 5px 2px 5px;
    color: #dc8f00;
    background-color: #dc8f0014;
    margin: 0px 10px 0px 10px;
    border-radius: 5px;
    text-transform: uppercase;
}

.FileListRowSize {
    display: block;
    font-size: 14px;
    font-weight: 600;
    color: #666;
    width: 120px;
    text-align: end;
}

.FileListRowAddressesColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-left: 20px;
    margin-right: 20px;
    width: 100px;
    
}

.FileListRowTotalAddresses {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: #666;
    text-align: end;

}

.FileListRowUniqueAddresses {
    display: block;
    font-size: 12px;
    font-weight: 600;
    color: #666;
    text-align: end;

}

.FileListRowCheckboxContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    color: #fff;
    border-radius: 45px;
    padding: 10px;
    cursor: pointer;
    margin-left: 0px;
    margin-right: 0px;
    transition: all 0.5s;
}

.FileListRowCheckboxContainer:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: fit-content;
    color: #fff;
    border-radius: 45px;
    background-color: #66666620;
}

.FileListRowCheckbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #b6b6b6;
    width: 15px;
    height: 15px;
    color: #ffffff00;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}

.FileListRowCheckboxChecked {
    background-color: #00a76f;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1.5px solid #00a76f;
    width: 15px;
    height: 15px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s;
}







.FileListPushToSendyBackground {
    min-width: 100vw;
    min-height: 100vh;
    background-color: #fff;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(28, 37, 46, 0.5); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
}

.FileListPushToSendyBackgroundHidden {
    opacity: 0;
    visibility: hidden;
    max-width: 0px;
    max-height: 0px;
    display: none;
    transition: all 0.3s;   
}

.FileListPushToSendyContainer {
    width: 800px;
    min-height: 500px;
    background-color: #1c252e;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) -40px 40px 80px -8px;
    transition: all 0.5s;
    position: relative;
}

.FileListPushToSendyContainerCloseIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    border-radius: 30px;
    color: #888;
    cursor: pointer;
    transition: all 0.5s;
}

.FileListPushToSendyTitle {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    width: calc(100% - 40px);
    padding: 20px

}

.FileListPushToSendyTitleListName {
    display: inline-block;
    font-weight: 600;
    color: #00a76f;
}

.FileListPushToSendyListNameInput {
    display: block;
    width: calc(100% - 40px);
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    background-color: #1c252e;
    border: 1px solid #666;
    color: #fff;
    font-size: 14px;
    outline: none;
    transition: all 0.5s;
}

.FileListPushToSendyAccountRowSelected {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 140px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #fff;
    position: relative;
    background-color: #1c252e;
    border-radius: 15px;
    color: #fff;
    transition: all 0.5s;
}

.FileListPushToSendyAccountRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 140px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #666;
    position: relative;
    background-color: #1c252e;
    border-radius: 15px;
    color: #fff;
    transition: all 0.5s;
}

